import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Splide from '@splidejs/splide';
import LazyLoad from "vanilla-lazyload";

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

gsap.registerPlugin(ScrollTrigger);

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]",
  callback_loaded: (el) => {
    gsap.to(el, {
      autoAlpha: 1,
      duration: .5,
      ease: 'power1.inOut',
      onComplete: () => {
        el.dispatchEvent(new Event('lazyloaded'));
      }
    })
  }
});

// --------------------------------------------------
// Get carousel number
// --------------------------------------------------

const carouselList = document.querySelectorAll('.carousel-container');  
// const allCards = cardList.querySelectorAll('.works-card');
// Create change effect for each portfolio navigation item

carouselList.forEach(carousel => {

  const carouselIndex = carousel.getAttribute("data-index");
  let carouselIndexNumber = null;

  ScrollTrigger.create({
    trigger: carousel,
    start: "top 50%",
    end: "bottom 25%",
    onToggle: self => {
      if(self.isActive) {
        console.log(carouselIndex);
        carouselIndexNumber = carouselIndex;
        var projectTitle = document.getElementById('project-title');
        projectTitle.textContent = document.getElementsByClassName('carousel-project-title')[carouselIndex].textContent;
        var photoNumber = document.getElementById('photo-number');
        photoNumber.textContent = document.getElementsByClassName('carousel-status')[carouselIndex].textContent; 
        // Modal contents
        var projectInfo = document.getElementById('project-info');
        projectInfo.innerHTML = document.getElementsByClassName('carousel-project-info')[carouselIndex].innerHTML;  
        var projectDescription = document.getElementById('project-description');
        projectDescription.innerHTML = document.getElementsByClassName('carousel-project-description')[carouselIndex].innerHTML; 
      }
    }
  });
  console.log(carouselIndexNumber);
});

// --------------------------------------------------
// Project modal
// --------------------------------------------------

// Get the Project modal
let modal = document.getElementById("myModal");

if(modal) {
  // Get the project modal back button
  var modalBack = document.getElementById("modal-back");
  // Get the button that opens the modal
  var btn = document.getElementById("project-title");
  // Get project section
  var projectsContainer = document.getElementById("projects-container");
  // When the user clicks the button, open the modal 
  btn.onclick = function() {
    modal.style.display = "block";
    document.body.style.minHeight = '100vh';
    document.body.style.overflow = 'hidden';
    document.body.style.overflowX = 'hidden';
    document.body.style.overscrollBehavior = 'none';
    document.documentElement.style.overflow = 'hidden';
    document.documentElement.style.overflowX = 'hidden';
    document.documentElement.style.overscrollBehavior = 'none';
    btn.classList.add('underlined');
    projectsContainer.classList.add('blurred');
  }
  // When the user clicks the back button, closethe modal 
  modalBack.onclick = function() {
    document.body.style.minHeight = 'initial';
    document.body.style.overflow = '';
    document.body.style.overflowX = '';
    document.body.style.overscrollBehavior = 'initial';
    document.documentElement.style.overflow = '';
    document.documentElement.style.overflowX = '';
    document.documentElement.style.overscrollBehavior = 'initial';
    modal.style.display = "none";
    btn.classList.remove('underlined');
    projectsContainer.classList.remove('blurred');
  }
}

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
  if (event.target == modal) {
    document.body.style.minHeight = 'initial';
    document.body.style.overflow = '';
    document.body.style.overflowX = '';
    document.body.style.overscrollBehavior = 'initial';
    document.documentElement.style.overflow = '';
    document.documentElement.style.overflowX = '';
    document.documentElement.style.overscrollBehavior = 'initial';
    modal.style.display = "none";
    btn.classList.remove('underlined');
    projectsContainer.classList.remove('blurred');
  }
  if (event.target == infoModal) {
    console.log('event.target == infoModal');
    document.body.style.minHeight = 'initial';
    document.body.style.overflow = '';
    document.body.style.overflowX = '';
    document.body.style.overscrollBehavior = 'initial';
    document.documentElement.style.overflow = '';
    document.documentElement.style.overflowX = '';
    document.documentElement.style.overscrollBehavior = 'initial';
    infoModal.style.display = "none";
    infoBtn.classList.remove('underlined');
    if (projectsContainer){
      projectsContainer.classList.remove('blurred');
    }
    if (collaboratorContainer){
      collaboratorContainer.classList.remove('blurred');
    }
  }
}

// Get the Info modal
var infoModal = document.getElementById("info-modal");
// Get the project modal back button
var infoModalBack = document.getElementById("info-modal-back");
// Get the button that opens the modal
var infoBtn = document.getElementById("firm-title");
// Get project section
var collaboratorContainer = document.getElementById("collaborators-container");

// When the user clicks the button, open the modal 
infoBtn.onclick = function() {
  infoModal.style.display = "block";
  document.body.style.minHeight = '100vh';
  document.body.style.overflow = 'hidden';
  document.body.style.overflowX = 'hidden';
  document.body.style.overscrollBehavior = 'none';
  document.documentElement.style.overflow = 'hidden';
  document.documentElement.style.overflowX = 'hidden';
  document.documentElement.style.overscrollBehavior = 'none';
  infoBtn.classList.add('underlined');
  if (projectsContainer){
    projectsContainer.classList.add('blurred');
  }
  if (collaboratorContainer){
    collaboratorContainer.classList.add('blurred');
  }
}
// When the user clicks the back button
infoModalBack.onclick = function() {
  console.log('back button');
  infoModal.style.display = "none";
  document.body.style.minHeight = 'initial';
  document.body.style.overflow = '';
  document.body.style.overflowX = '';
  document.body.style.overscrollBehavior = '';
  document.documentElement.style.overflow = '';
  document.documentElement.style.overflowX = '';
  document.documentElement.style.overscrollBehavior = '';
  infoBtn.classList.remove('underlined');
  if (projectsContainer){
    projectsContainer.classList.remove('blurred');
  }
  if (collaboratorContainer){
    collaboratorContainer.classList.remove('blurred');
  }
}

// --------------------------------------------------
// 🎠 Carousels
// --------------------------------------------------

// Initialize all Splide instances
var splideContainers = document.querySelectorAll('.carousel-container');
var customCursor = document.getElementById('custom-cursor');
console.log(customCursor);

if (splideContainers) {

  splideContainers.forEach(function(container) {

    var splide = new Splide(container.querySelector('.splide'), {
      // Your options for each carousel
      type: 'fade',
      rewind: true,
      speed: 0,
      pauseOnHover: false,
      autoplay: false,
      arrows: false,
      pagination: false,
      autoHeight: true,
      focus: 'center',
      width: '100%',
      updateOnMove: true
    }).mount();

    var end  = splide.Components.Controller.getEnd() + 1;
    splide.on('move', (e) => {
      let cs = container.querySelector('.carousel-status__changing-number');
      cs.innerHTML = splide.index + 1;
      var photoNumber = document.getElementById('photo-number');
      photoNumber.textContent = splide.index + 1 + '/' + end;

      // update();
    })

    // Click to change slide
    let nextButtons = container.querySelectorAll('.carousel');
    if(nextButtons) {
      nextButtons.forEach((item) => {
        // Get the width of the div
        const divWidth = item.clientWidth;
        item.addEventListener( 'click', function(event) {
          
          // Get the horizontal position of the click event
          var clickX = event.clientX - item.getBoundingClientRect().left;
          
          // Calculate the distance from the left and right edges
          var distanceToLeft = clickX;
          var distanceToRight = divWidth - clickX;
          
          if (distanceToLeft < distanceToRight) {
            splide.go('<');
          } else {
            splide.go('>');
          }
        })
        item.addEventListener('mousemove', function(event) {
          
          // Get the horizontal position of the cursor within the div
          var cursorX = event.clientX - this.getBoundingClientRect().left;
          
          // Calculate the distance from the cursor to the left and right edges
          var distanceToLeft = cursorX;
          var distanceToRight = divWidth - cursorX;
          
          if (distanceToLeft < distanceToRight) {
              customCursor.innerHTML = '<span><</span> Ant';
              console.log('Cursor is closer to the left side of the div');
              item.setAttribute('data-mouse', 'left');
          } else {
              customCursor.innerHTML = 'Sig <span>></span>';
              console.log('Cursor is closer to the right side of the div');
              item.setAttribute('data-mouse', 'right');
          }
        });
        container.querySelector('.splide__track').addEventListener('mouseover', function() {
          customCursor.classList.remove('hidden');
          document.body.style.cursor = 'none';
          console.log('mouseover');
        });
        container.querySelector('.splide__track').addEventListener('mouseout', function() {
          customCursor.classList.add('hidden');
          document.body.style.cursor = 'auto';
        });
        container.addEventListener('mousemove', function(e) {
          const x = e.clientX;
          const y = e.clientY;
      
          customCursor.style.left = `${x}px`;
          customCursor.style.top = `${y}px`;
        });
      });
    };

  });

};